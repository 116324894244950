var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_journal_lines") } },
    [
      _c(
        "a-card-grid",
        { staticStyle: { width: "100%" }, attrs: { hoverable: false } },
        [
          _c(
            "a-form-model",
            _vm._b(
              { attrs: { model: _vm.form, "label-align": "left" } },
              "a-form-model",
              _vm.formItemLayout,
              false
            ),
            [
              _c(
                "a-form-model-item",
                { attrs: { label: _vm.$t(_vm.formRules.docNumber.label) } },
                [
                  _c("a-input", {
                    attrs: {
                      placeholder: _vm.$t(_vm.formRules.docNumber.placeholder),
                      disabled: true
                    },
                    model: {
                      value: _vm.form.docNumber,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "docNumber", $$v)
                      },
                      expression: "form.docNumber"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: { label: _vm.$t(_vm.formRules.transactionDate.label) }
                },
                [
                  _c("a-date-picker", {
                    staticClass: "w-100",
                    attrs: {
                      placeholder: _vm.$t(
                        _vm.formRules.transactionDate.placeholder
                      ),
                      disabled: true,
                      format: _vm.DEFAULT_DATE_FORMAT
                    },
                    model: {
                      value: _vm.form.transactionDate,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "transactionDate", $$v)
                      },
                      expression: "form.transactionDate"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: _vm.$t(_vm.formRules.docReference.label) } },
                [
                  _c("a-input", {
                    attrs: {
                      placeholder: _vm.$t(
                        _vm.formRules.docReference.placeholder
                      ),
                      disabled: true
                    },
                    model: {
                      value: _vm.form.docReference,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "docReference", $$v)
                      },
                      expression: "form.docReference"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: _vm.$t(_vm.formRules.description.label) } },
                [
                  _c("a-textarea", {
                    attrs: {
                      placeholder: _vm.$t(
                        _vm.formRules.description.placeholder
                      ),
                      disabled: true
                    },
                    model: {
                      value: _vm.form.description,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "description", $$v)
                      },
                      expression: "form.description"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: _vm.$t(_vm.formRules.source.label) } },
                [
                  _c("span", { staticClass: "ant-form-text" }, [
                    _vm._v(_vm._s(_vm.form.source || "-"))
                  ])
                ]
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: _vm.$t(_vm.formRules.status.label) } },
                [
                  _c("span", { staticClass: "ant-form-text" }, [
                    _vm._v(_vm._s(_vm.form.status || "-"))
                  ])
                ]
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: _vm.$t(_vm.formRules.attachment.label) } },
                [
                  _vm.attachment &&
                  _vm.form.attachment &&
                  _vm.isImg(_vm.form.attachment)
                    ? [
                        _c(
                          "a-button",
                          {
                            staticClass: "p-0",
                            attrs: { type: "link" },
                            on: { click: _vm.toggleShowImage }
                          },
                          [_vm._v(" " + _vm._s(_vm.form.attachment) + " ")]
                        ),
                        _c("VueEasyLightbox", {
                          attrs: {
                            visible: _vm.showImage,
                            index: 0,
                            imgs: _vm.attachment
                          },
                          on: { hide: _vm.toggleShowImage }
                        })
                      ]
                    : _vm.form.attachment
                    ? [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.attachment || "javascript:void(0)",
                              target: "_blank",
                              rel: "nooppener noreferrer",
                              download: _vm.form.attachment
                            }
                          },
                          [_vm._v(_vm._s(_vm.form.attachment))]
                        )
                      ]
                    : [
                        _c(
                          "span",
                          [
                            _c("a-icon", { attrs: { type: "file" } }),
                            _vm._v(" " + _vm._s(_vm.$t("lbl_file_not_found")))
                          ],
                          1
                        )
                      ]
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-card-grid",
        { staticStyle: { width: "100%" }, attrs: { hoverable: false } },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                [
                  _vm.isView
                    ? _c("TableCustom", {
                        attrs: {
                          idtable: "table1",
                          "on-select-change": null,
                          "selected-row-keys": null,
                          "data-source":
                            _vm.dtDetailGeneralJournal.journalLineDTOS,
                          columns: _vm.columnsTableView,
                          scroll: { x: "calc(700px + 50%)" },
                          paginationcustom: false,
                          "default-pagination": true,
                          loading: _vm.loading.table,
                          "footer-right": {
                            debit: _vm.totalDebitValue,
                            credit: _vm.totalCreditValue
                          }
                        }
                      })
                    : _c("TableCustom", {
                        attrs: {
                          idtable: "table1",
                          "on-select-change": null,
                          "selected-row-keys": null,
                          "data-source":
                            _vm.dtDetailGeneralJournal.journalLineDTOS,
                          columns: _vm.columnsTable,
                          scroll: { x: "calc(700px + 50%)" },
                          paginationcustom: false,
                          "default-pagination": true,
                          loading: _vm.loading.table,
                          "footer-right": {
                            debit: _vm.totalDebitValue,
                            credit: _vm.totalCreditValue
                          }
                        }
                      })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            {
              attrs: {
                gutter: [16, 16],
                type: "flex",
                justify: "space-between",
                align: "middle"
              }
            },
            [
              _c(
                "a-col",
                { attrs: { sm: 24, md: 12 } },
                [
                  _c("a-tag", { attrs: { color: "grey" } }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("lbl_total_found")) +
                        " : " +
                        _vm._s(
                          _vm.dtDetailGeneralJournal.journalLineDTOS.length
                        ) +
                        " "
                    )
                  ])
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { sm: 24, md: 12, align: "end" } },
                [
                  _c(
                    "a-space",
                    [
                      _c("a-button", { on: { click: _vm.handleBack } }, [
                        _vm._v(" " + _vm._s(_vm.$t("lbl_back")) + " ")
                      ]),
                      _c(
                        "a-button",
                        {
                          attrs: {
                            icon: "download",
                            type: "primary",
                            loading: _vm.loading.download
                          },
                          on: { click: _vm.handleDownload }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_download")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }