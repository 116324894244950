





















































































































































import { downloadFile, isImg, transformDataURL } from "@/helpers/file-reader";
import { DEFAULT_DATE_FORMAT } from "@/models/constant/date.constant";
import { DataListGeneralJournal, DataListJournalLines } from "@/models/interface-v2/general-journal.interface";
import { fileServices } from "@/services-v2/file.service";
import { generalJournalServices } from "@/services-v2/general-journal.service";
import { currencyFormat } from "@/validator/globalvalidator";
import moment, { Moment } from "moment";
import Vue from "vue";
import VueEasyLightbox from "vue-easy-lightbox";

export default Vue.extend({
  name: "DetailJournal",
  components: {
    VueEasyLightbox,
  },
  data() {
    return {
      DEFAULT_DATE_FORMAT,
      form: {
        docNumber: "",
        transactionDate: null as Moment | null,
        docReference: "",
        description: "",
        source: "",
        status: "",
        attachment: "" as string | null,
      },
      isView: false as boolean,
      totalData: 0 as number,
      limit: 10 as number,
      dtDetailGeneralJournal: {
        journalLineDTOS: [] as DataListJournalLines[]
      } as DataListGeneralJournal,
      totalCreditValue: "" as string,
      totalDebitValue: "" as string,
      columnsTableView: [
        {
          title: this.$t("lbl_account_name"),
          dataIndex: "accountDescription",
          key: "accountId",
          sorter: false,
          width: 100,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_code"),
          dataIndex: "accountCode",
          key: "code",
          sorter: false,
          width: 100,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_description"),
          dataIndex: "description",
          key: "description",
          sorter: false,
          width: 100,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_debit"),
          dataIndex: "debit",
          key: "debit",
          sorter: false,
          width: 100,
          scopedSlots: { customRender: "isCurrency" },
        },
        {
          title: this.$t("lbl_credit"),
          dataIndex: "credit",
          key: "credit",
          sorter: false,
          width: 100,
          scopedSlots: { customRender: "isCurrency" },
        },
      ],
      columnsTable: [
        {
          title: this.$t("lbl_account_name"),
          dataIndex: "accountDescription",
          key: "accountId",
          sorter: false,
          width: 100,
          scopedSlots: { customRender: "isNull" },
          responsiveColInput: [
            {
              title: this.$t("lbl_description"),
              dataIndex: "description",
              key: "description",
              sorter: false,
              width: 100,
              scopedSlots: { customRender: "isNull" },
            },
            {
              title: this.$t("lbl_debit"),
              dataIndex: "debit",
              key: "debit",
              sorter: false,
              width: 100,
              scopedSlots: { customRender: "isCurrency" },
            },
            {
              title: this.$t("lbl_credit"),
              dataIndex: "credit",
              key: "credit",
              sorter: false,
              width: 100,
              scopedSlots: { customRender: "isCurrency" },
            },
          ],
          responsiveColSelect:[
            {
              name: "description",
              placeholder: this.$t("lbl_account_name_placeholder"),
              value: "description",
              style: "width: 100px",
              options: [] as any
            },
            {
              name: "code",
              placeholder: this.$t("lbl_account_code_placeholder"),
              value: "code",
              style: "width: 100px",
              options: [] as any
            }
          ]
        },
        {
          title: this.$t("lbl_code"),
          dataIndex: "accountCode",
          key: "code",
          sorter: false,
          width: 100,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_description"),
          dataIndex: "description",
          key: "description",
          sorter: false,
          width: 100,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_debit"),
          dataIndex: "debit",
          key: "debit",
          sorter: false,
          width: 100,
          scopedSlots: { customRender: "isCurrency" },
        },
        {
          title: this.$t("lbl_credit"),
          dataIndex: "credit",
          key: "credit",
          sorter: false,
          width: 100,
          scopedSlots: { customRender: "isCurrency" },
        },

      ] as any,
      formRules: {
        docNumber: {
          label: "lbl_document_number",
          name: "Doc Number",
          placeholder: "lbl_type_here",
        },
        transactionDate: {
          label: "lbl_transaction_date",
          name: "Transaction Date",
          placeholder: "lbl_choose",
        },
        docReference: {
          label: "lbl_document_reference",
          name: "Doc Reference",
          placeholder: "lbl_type_here",
        },
        attachment: {
          label: "lbl_attachment",
          name: "Attachment",
        },
        status: {
          label: "lbl_status",
          name: "Status",
        },
        description: {
          label: "lbl_description",
          name: "Description",
          placeholder: "lbl_description"
        },
        source: {
          label: "lbl_source",
          name: "Source",
        },
      },
      loading: {
        download: false,
        table: false,
      },
      journalId: "",
      attachment: null as string | ArrayBuffer | null,
      showImage: false,
    };
  },
  computed: {
    formItemLayout() {
      return {
        labelCol: { span: 8 },
        wrapperCol: { span: 14 },
      };
    },
  },
  created() {
    if (this.$route.params.id) {
      this.journalId = this.$route.params.id;
      this.getDetailGeneralJournal(this.journalId);
    }
  },
  methods: {
    moment,
    isImg,
    toggleShowImage() {
      this.showImage = !this.showImage;
    },
    getDetailGeneralJournal(id: string): void {
      this.loading.table = true;
      generalJournalServices.detailGeneralJournal(id)
      .then((res: DataListGeneralJournal) => {
        res.journalLineDTOS.forEach((dataObject, index) => {
          dataObject["key"] = index;
        });
        this.dtDetailGeneralJournal = res;
        this.form = {
          docNumber: res.name,
          docReference: res.documentReference,
          transactionDate: this.moment(res.date),
          description: res.description,
          source: res.source,
          status: res.status,
          attachment: res.attachment,
        };
        let totalDebit = [] as number[];
        let totalCredit = [] as number[];
        res.journalLineDTOS.forEach((item) => {
          totalDebit.push(item.debit);
          totalCredit.push(item.credit);
        });
        this.totalCreditValue = currencyFormat(
          totalCredit.reduce((a, b) => a + b, 0)
        );
        this.totalDebitValue = currencyFormat(
          totalDebit.reduce((a, b) => a + b, 0)
        );
        if (this.form.attachment) {
          this.getAttachment(this.form.attachment);
        }
      })
      .catch(() => {
        this.$message.error(this.$t("lbl_upload_info_1").toString());
      })
      .finally(() => this.loading.table = false);
    },
    async getAttachment(filename: string): Promise<void> {
      try {
        if (!filename) return;
        const blob = await fileServices.getFile(filename);
        this.attachment = await transformDataURL(blob);
      } catch (error) {
        this.$message.error(this.$t("notif_file_get_fail", { filename }).toString());
      }
    },
    handleBack(): void {
      this.$router.push({ name: "generaljournal.read" });
    },
    async handleDownload(): Promise<void> {
      try {
        this.loading.download = true;
        const file = await generalJournalServices.downloadDetail(this.journalId);
        downloadFile(new Blob([file]), `report_journal_${this.dtDetailGeneralJournal.name}.xlsx`);
      } catch (error) {
        this.showErrorMessage("notif_download_error");
      } finally {
        this.loading.download = false;
      }
    },
  },
});
